<template>
  <div style="height: 100%">
    <v-header></v-header>
    <!-- 订单页面   -->
    <div class="order-wrap">
      <div class="list-options-wrap">
        <cube-radio-group
          v-model="radioOptionsSelected"
          :options="bagDeskOptions"
          :col-num="2"
          :hollow-style="true"
          @input="changeRadio"
        />
      </div>
      <div class="list-options-wrap">
        <van-search
          v-model="searchCode"
          show-action
          shape="round"
          placeholder="请输入订单编号"
          style="width: 280px"
        >
          <template #action>
            <div style="margin-bottom: 5px;">
              <!--搜索-->
              <van-button @click="onSearch" icon="search" type="default" size="mini">搜索</van-button>
              <!--              <div @click="onSearch" style="background-color: #07c160">搜索</div>-->
            </div>
          </template>
        </van-search>
        <!-- 扫一扫-->
        <van-button icon="scan"  @click="goScan()" type="default" size="mini" style="margin-top: 19px;margin-right: 18px">扫一扫</van-button>
      </div>
      <!-- 订单号、订单时间、订餐人姓名、总价 -->
<!--      <cube-scroll-->
<!--        class="list-content"-->
<!--        ref="listContent"-->
<!--        :options="scrollOptions"-->
<!--      >-->
        <div class="list-container">
          <ul class="orderList-wrap">
            <li
              class="orderList-container"
              v-for="(order, index) in userOrderData.list"
              :key="index"
              @click.stop="goDetail(order)"
            >
              <div class="orderList-top-container">
                <div class="order-state">
                  订单状态：<span>{{ order.auditStatus.display }}</span>
                </div>
                <button v-if="order.auditStatus.code == 3" class="order-display-button-disable" disabled>
                  已取消
                </button>
              </div>
              <div class="orderList-number">
                订单号：<span>{{ order.orderCode }}</span>
              </div>
              <div class="orderList-time">
                订单时间：<span>{{ order.createTime }}</span>
              </div>
              <div class="orderList-userName">
                订餐人姓名：<span>{{ order.buyersName }}</span>
              </div>
              <div class="orderList-totalPrice" v-if="order.totalPriceView">
                总价：<span>RP：{{ order.totalPriceView }}</span>
              </div>
            </li>
          </ul>
        </div>
<!--      </cube-scroll>-->
      <van-popup
        v-model="mealCode"
        get-container="body"
        style="width: 300px;height: 250px"
      >
        <div class="food-name">
          <div style="padding-left: 5%;padding-top:7%;font-size: 14px">订单编码:
            <span style="font-size: 16px;padding-left: 2px">{{ this.orderCode }}</span>
          </div>
        </div>
        <div style="margin-left: 25%;margin-top:5%;border: 1px solid #e4e4e4;width:160px;height:160px">
          <div id="qrcode" class="qrcode" ref="qrCodeUrl" style="margin-left: 5px;margin-top: 5px"></div>
        </div>
      </van-popup>
      <van-pagination
        class="order-pagination"
        v-model="requestOrderData.pageNum"
        :total-items="userOrderData.total"
        :items-per-page="requestOrderData.pageSize"
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import { cutThePrice } from '@/utils/tool.js'
import VHeader from 'components/v-Adminheader/v-header'
import { selectOrderTable, getUserOrder, getOrder } from 'api'
import Vue from 'vue'
import { Toast, Button } from 'vant'
Vue.use(Toast)
Vue.use(Button)

export default {
  name: 'login',
  components: { VHeader },
  data () {
    return {
      orderCode: '',
      searchCode: '',
      // orderfalg true菜单 false包桌
      orderfalg: true,
      mealCode: false,
      // 请求餐单列表参数
      requestOrderData: {
        pageSize: 10,
        pageNum: 1,
        buyersPhone: '',
        orderCode: ''
      },
      // 用户菜单列表
      userOrderData: [],
      // cube scroll参数
      scrollOptions: {
        click: false,
        directionLockThreshold: 0
      },
      // 单选参数
      bagDeskOptions: [
        { label: '菜品订单', value: 1 },
        { label: '包桌订单', value: 0 }
      ],
      // 选择订单类别
      radioOptionsSelected: 1
    }
  },
  methods: {
    // 获取订单信息
    getUserOder () {
      if (this.radioOptionsSelected) {
        getUserOrder(this.requestOrderData)
          .then((res) => {
            res.object.list.forEach((item) => {
              item.totalPriceView = cutThePrice(item.amount)
            })
            this.userOrderData = res.object
            console.log(this.userOrderData)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        selectOrderTable(this.requestOrderData)
          .then((res) => {
            res.object.list.forEach((item) => {
              item.totalPriceView = cutThePrice(item.amount)
            })
            this.userOrderData = res.object
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 修改页码
    async changePage (e) {
      this.requestOrderData.pageNum = e
      await this.getUserOder()
    },
    // 详情页
    goDetail (e) {
      let codeOrder = ''
      if (this.radioOptionsSelected) {
        codeOrder = 'C' + e.orderCode // C 代表餐品订单
      } else {
        codeOrder = 'B' + e.orderCode // B 代表包桌订单
      }
      this.$router.push({
        path: '/admin/takeAMeal',
        query: { encryptionOrderCode: codeOrder }
      })
      console.log(codeOrder)
    },
    // 扫一扫页面
    goScan () {
      this.$router.push({ name: 'admin/scan' })
    },
    onSearch () {
      this.requestOrderData.orderCode = this.searchCode
      console.log(this.requestOrderData.orderCode)
      console.log(this.orderfalg)
      if (this.orderfalg) {
        // 搜索菜品
        getOrder(this.requestOrderData)
          .then((res) => {
            if (res.object.list.length === 0) {
              Toast.fail('暂无数据')
              this.searchCode = ''
              this.requestOrderData.orderCode = ''
            } else {
              res.object.list.forEach((item) => {
                item.totalPriceView = cutThePrice(item.amount)
              })
              this.userOrderData = res.object
              console.log(this.userOrderData)
              this.requestOrderData.orderCode = ''
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        // 搜索包桌
        selectOrderTable(this.requestOrderData)
          .then((res) => {
            console.log(res)
            if (res.object.list.length === 0) {
              Toast.fail('暂无数据')
              this.searchCode = ''
              this.requestOrderData.orderCode = ''
            } else {
              res.object.list.forEach((item) => {
                item.totalPriceView = cutThePrice(item.amount)
              })
              this.userOrderData = res.object
              this.requestOrderData.orderCode = ''
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    async changeRadio (e) {
      this.orderfalg = !this.orderfalg
      this.radioOptionsSelected = e
      this.requestOrderData.pageNum = 1
      await this.getUserOder()
    },
    // 登录验证
    validateLogin () {
      var data = sessionStorage.getItem('loginAdmin')
      var loginAdmin = JSON.parse(data)
      if (loginAdmin === null) {
        Toast.fail('请进行管理员登录')
        this.$router.push({ name: 'admin/login' })
      } else if (loginAdmin.roles !== 'ADMIN') {
        Toast.fail('暂无权限')
        this.$router.push({ name: 'admin/login' })
      }
    }
  },
  mounted () {
    this.validateLogin()
    this.getUserOder()
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.list-options-wrap
  display: flex
  justify-content: space-between
  padding:0 18px
  >>> .cube-radio-group
    width 100%
    border:0px !important
    font-size: $fontsize-large
  >>> .cube-radio
    padding-left:0
    padding-right:15px
  >>> .border-top-1px::before
    border:none
  >>> .cube-radio-group::after
    border:none

.order-wrap
  height 100%
  .list-content
    padding: 0 18px
    height: 48%
    overflow: hidden
    background: $color-white
  .orderList-wrap
    width 100%
    display: flex
    flex-wrap: wrap
    .orderList-container
      width 90%
      margin: 15px auto
      background: $color-white
      border-radius: 5px
      border: 1px dashed  $color-background-sss
      .orderList-top-container
        display: flex
        border-radius: 5px
        font-size: $fontsize-large
        justify-content: space-between
        margin: 0
        .order-state
          display: flex
          font-weight: 700
          span
            font-weight: 700
            color: $color-red
        .order-display-button
          margin-right: 5px
          border-radius: 5px
          border: none
          background: $color-blue
          padding: 5px
          color: #fff
        .order-display-button-disable
          box-shadow: none
          padding: 5px
          margin-right: 5px
          border-radius: 5px
          border:none
          padding: 5px
          color: rgba(255,0,0,0.5)
          background: $color-background-sss
      div
        margin: 8px 0
        font-size: 15px
        display: flex
        align-items: center
        padding-left: 5px
        span
          font-weight: 700
      .orderList-totalPrice
        span
          color: red
  .list-container
    padding: 0 18px;
    height: 48%;
    overflow-y: scroll;
    background: #fff;
  .order-pagination
    margin-top: 10px
.login-order-wrap
  display: flex
  justify-content: center
  position relative
  top: 20%
  .login-order-container
    margin-top 20px
    padding: 0 20px
    flex-direction: column
    border-radius: 5px
    border: 1px solid #ccc
    background: #fff
    display: flex
    justify-content: center
    .title
      text-align: center
      font-size: 20px
      font-weight: 700
      padding: 15px
    .login-account-container
      padding 10px 0
      display: flex
      align-items: center
    .login-password-container
      padding 10px 0
      display: flex
      align-content: center
    input
      border: 2px solid #ccc
      border-radius: 5px
    button
      border: none
      border-radius: 5px
      overflow hidden
      background: #1E90FF
      width 90%
      font-size: 14px
      margin:10px auto
      padding: 4px 0
      color: #fff
</style>
