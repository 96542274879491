<template>
  <div class="order-wrap">
    <div class="top-container">
      <van-icon @click="goBack" class="top-icon" size="25" name="arrow-left" />
      <div class="top-text">
        订单：{{deskOrderCode }}
      </div>
    </div>
    <cube-scroll
      class="list-content"
      ref="listContent"
      :options="scrollOptions"
    >
      <div class="main">
        <div class="food-name">
          <span>菜品名称</span>
          <span v-if="!isDesk">菜品价格</span>
          <!-- <span v-else>包桌名称</span> -->
        </div>
        <div class="food-wrap">
          <div
            class="food-container"
            v-for="(item, index) in detailData.orderFoodDetailsPo"
            :key="index"
          >
            <div class="food-container-top">
              <div class="food-container-title">
                <div class="food-index">{{ index + 1 }}.</div>
                <div class="food-title">{{ item.foodName }}</div>
              </div>
              <div class="food-num" v-if="!isDesk">
                <span>x</span>{{ item.foodSum }}
              </div>
              <div class="food-num" v-else><span>x</span>{{ item.number }}</div>
              <div class="food-price" v-if="!isDesk">
                RP {{ item.price }}
              </div>
              <!-- <div class="food-price" else>{{ item.orderTableName }}</div> -->
            </div>
            <!-- <div class="food-container-bottom">{{ item.categoryName }}</div> -->
          </div>
          <div class="total-price">
            总金额： <span>RP：{{ detailData.amount }}</span>
          </div>
        </div>
        <div class="order-createTime">
          <span>下单时间：</span>
          {{ detailData.createTime }}
        </div>
        <div class="order-serialNumber" v-if="!isDesk">
          <span>结算流水号：</span>
          {{ detailData.serialNumber ? detailData.serialNumber : '' }}
        </div>
        <div class="oder-state">
          <span>订单状态：</span>
          {{ detailData.display }}
        </div>
        <div class="oder-button" v-if="isTake">
          <van-button round size="large" type="primary" @click="takeAMeal()">  取餐  </van-button>
        </div>
      </div>
    </cube-scroll>
  </div>
</template>

<script>
import { getOrderByOrderCOde, takeAMeal } from 'api'
import Vue from 'vue'
import { Button, Toast } from 'vant'

Vue.use(Button)
Vue.use(Toast)

export default {
  data () {
    return {
      scrollOptions: {
        click: false,
        directionLockThreshold: 0
      },
      detailData: {
        auditStatus: '',
        orderFoodDetailsPo: ''
      },
      deskOrderCode: '',
      isDesk: true,
      orderCode: '',
      isTake: false
    }
  },
  mounted () {
    this.validateLogin()
    // 获取订单编号
    this.orderCode = location.href.split('?encryptionOrderCode=')[1]
    const flag = this.orderCode.substr(0, 1)
    console.log(flag)
    if (flag === 'B') {
      this.isDesk = true
    } else {
      this.isDesk = false
    }
    this.getOrderByOrderCOde(this.orderCode)
  },
  methods: {
    // 查询订单信息
    getOrderByOrderCOde (orderCode) {
      getOrderByOrderCOde(orderCode)
        .then((res) => {
          console.log(res)
          this.deskOrderCode = res.object.orderCode
          this.detailData = res.object
          // 状态
          if (this.detailData.auditStatus === 'ALL') {
            this.detailData.display = '已付款'
            this.isTake = true
          } else if (this.detailData.auditStatus === 'INVALID') {
            this.detailData.display = '已失效'
            this.isTake = false
          } else if (this.detailData.auditStatus === 'MealTaken') {
            this.detailData.display = '已取餐'
            this.isTake = false
          } else if (this.detailData.auditStatus === 'APPROVALSUCCESS') {
            this.detailData.display = '审批通过'
            this.isTake = false
          } else if (this.detailData.auditStatus === 'APPROVALFAIL') {
            this.detailData.display = '审批未通过'
            this.isTake = false
          } else if (this.detailData.auditStatus === 'APPROVALPROCEEDS') {
            this.detailData.display = '待付款'
            this.isTake = false
          }
          // 包桌子信息
          if (this.isDesk) {
            this.detailData.orderFoodDetailsPo = res.object.orderTableFoodVo
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 取餐
    takeAMeal () {
      takeAMeal(this.orderCode)
        .then((res) => {
          console.log(res)
          if (res.message_code === 'success') {
            Toast.success(res.object)
            this.getOrderByOrderCOde(this.orderCode)
          } else {
            Toast.fail(res.object)
            this.getOrderByOrderCOde(this.orderCode)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 登录验证
    validateLogin () {
      var data = sessionStorage.getItem('loginAdmin')
      var loginAdmin = JSON.parse(data)
      if (loginAdmin === null) {
        Toast.fail('请进行管理员登录')
        this.$router.push({ name: 'admin/login' })
      } else if (loginAdmin.roles !== 'ADMIN') {
        Toast.fail('暂无权限')
        this.$router.push({ name: 'admin/login' })
      }
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/variable"
.order-wrap
  background: $color-white
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
.top-container
  width 100%
  background: #87CEEB
  display: flex
  padding: 20px 0
  align-items: center
  .top-icon
    padding: 0 10px
  .top-text
    font-size: 18px
    font-weight: 700
.list-content
  margin: 40px
  width 100%
  height: 80%
  overflow: hidden
  background: $color-white
  .main
    background: $color-background-ssss
    width 80%
    height: 80%
    margin: auto
    padding: 20px
    border-radius: 10px
    .food-name
      display: flex
      justify-content: space-between
      font-size: 20px
      padding-bottom: 20px
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .food-wrap
      display: flex
      flex-direction: column
      .food-container
        display: flex
        flex-direction: column
        margin: 8px 10px
        .food-container-top
          display: flex
          justify-content: space-between
          .food-container-title
            display: flex
            .food-index
              font-size: $fontsize-large
              font-weight: 700
            .food-title
              font-size: $fontsize-large
              font-weight: 700
          .food-num
            font-size: $fontsize-large
            font-weight: 700
            span
              font-size: $fontsize-small-s
              font-weight: 700
          .food-price
            font-size: $fontsize-large
            font-weight: 700
            color: $color-blue

        .food-container-bottom
          background: $color-background-sss
          color: $ccc
          padding: 4px
          border-radius: 5px
          margin: 8px
          font-weight: 700
      .total-price
        display: flex
        justify-content: space-between
        font-size:$fontsize-large-x
        padding-bottom: 10px
        border-bottom: 2px solid #ccc
        font-weight: 700
        span
          color: $color-blue
          font-size:$fontsize-large-xxx
          border-bottom: 1px solid $color-blue
          font-weight: 700
    .order-createTime
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .order-serialNumber
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .oder-state
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      color $color-red
      border-bottom: 2px solid #ccc
      span
        color #000
        font-weight: 700
    .oder-button
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      span
        color #000
        font-weight: 700
</style>
