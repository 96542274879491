<template>
  <div class="order-wrap">
    <div class="top-container">
      <van-icon @click="goBack" class="top-icon" size="25" name="arrow-left" />
      <div class="top-text">
        订单：{{ detailData.orderCode ? detailData.orderCode : deskOrderCode }}
      </div>
    </div>
    <cube-scroll
      class="list-content"
      ref="listContent"
      :options="scrollOptions"
    >
      <div class="main">
        <div class="food-name">
          <span>菜品名称</span>
          <span v-if="!isDesk">菜品价格</span>
          <!-- <span v-else>包桌名称</span> -->
        </div>
        <div class="food-wrap">
          <div
            class="food-container"
            v-for="(item, index) in detailData.orderFoodDetailsPo"
            :key="index"
          >
            <div class="food-container-top">
              <div class="food-container-title">
                <div class="food-index">{{ index + 1 }}.</div>
                <div class="food-title">{{ item.foodName }}</div>
              </div>
              <div class="food-num" v-if="!isDesk">
                <span>x</span>{{ item.foodSum }}
              </div>
              <div class="food-num" v-else><span>x</span>{{ item.number }}</div>
              <div class="food-price" v-if="!isDesk">
                RP {{ item.priceView }}
              </div>
              <!-- <div class="food-price" else>{{ item.orderTableName }}</div> -->
            </div>
            <!-- <div class="food-container-bottom">{{ item.categoryName }}</div> -->
          </div>
          <div class="total-price">
            总金额： <span>RP：{{ detailData.totalPriceView }}</span>
          </div>
        </div>
        <div class="order-createTime">
          <span>下单时间：</span>
          {{ detailData.createTime }}
        </div>
        <div class="order-serialNumber" v-if="!isDesk">
          <span>结算流水号：</span>
          {{ detailData.serialNumber ? detailData.serialNumber : '' }}
        </div>
        <div class="oder-state">
          <span>订单状态：</span>
          {{ detailData.auditStatus.display }}
        </div>
      </div>
    </cube-scroll>
  </div>
</template>

<script>
import { cutThePrice } from '@/utils/tool.js'
import { getUserDeskOderDetail } from 'api'

export default {
  data () {
    return {
      scrollOptions: {
        click: false,
        directionLockThreshold: 0
      },
      deskRequestData: {
        pageNum: 1,
        pageSize: 999,
        orderTableId: this.$route.params.detailData.id
      },
      detailData: {
        auditStatus: ''
      },
      isDesk: this.$route.params.isDesk,
      deskOrderCode: ''
    }
  },
  mounted () {
    if (!this.$route.params.isDesk) {
      const detailObject = Object.assign({}, this.$route.params.detailData)
      detailObject.totalPriceView = cutThePrice(detailObject.amount)
      detailObject.orderFoodDetailsPo.forEach((food) => {
        food.priceView = cutThePrice(food.price * food.foodSum)
      })
      this.detailData = detailObject
      console.log(this.detailData.auditStatus.display)
    } else {
      getUserDeskOderDetail(this.deskRequestData)
        .then((res) => {
          // console.log(res)
          this.deskOrderCode = res.object.list[0].orderCode
          const detailObject = Object.assign({}, this.$route.params.detailData)
          detailObject.orderFoodDetailsPo = res.object.list
          this.detailData = detailObject
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/variable"
.order-wrap
    background: $color-white
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
  .top-container
    width 100%
    background: #87CEEB
    display: flex
    padding: 20px 0
    align-items: center
    .top-icon
      padding: 0 10px
    .top-text
      font-size: 18px
      font-weight: 700
  .list-content
    margin: 40px
    width 100%
    height: 80%
    overflow: hidden
    background: $color-white
    .main
      background: $color-background-ssss
      width 80%
      height: 80%
      margin: auto
      padding: 20px
      border-radius: 10px
      .food-name
        display: flex
        justify-content: space-between
        font-size: 20px
        padding-bottom: 20px
        border-bottom: 2px solid #ccc
        span
          font-weight: 700
      .food-wrap
        display: flex
        flex-direction: column
        .food-container
          display: flex
          flex-direction: column
          margin: 8px 10px
          .food-container-top
            display: flex
            justify-content: space-between
            .food-container-title
              display: flex
              .food-index
                font-size: $fontsize-large
                font-weight: 700
              .food-title
                font-size: $fontsize-large
                font-weight: 700
            .food-num
              font-size: $fontsize-large
              font-weight: 700
              span
                font-size: $fontsize-small-s
                font-weight: 700
            .food-price
              font-size: $fontsize-large
              font-weight: 700
              color: $color-blue

          .food-container-bottom
            background: $color-background-sss
            color: $ccc
            padding: 4px
            border-radius: 5px
            margin: 8px
            font-weight: 700
        .total-price
          display: flex
          justify-content: space-between
          font-size:$fontsize-large-x
          padding-bottom: 10px
          border-bottom: 2px solid #ccc
          font-weight: 700
          span
            color: $color-blue
            font-size:$fontsize-large-xxx
            border-bottom: 1px solid $color-blue
            font-weight: 700
      .order-createTime
        display: flex
        justify-content: space-between
        font-size:$fontsize-large-x
        font-weight: 700
        padding: 20px 0
        border-bottom: 2px solid #ccc
        span
          font-weight: 700
      .order-serialNumber
        display: flex
        justify-content: space-between
        font-size:$fontsize-large-x
        font-weight: 700
        padding: 20px 0
        border-bottom: 2px solid #ccc
        span
          font-weight: 700
      .oder-state
        display: flex
        justify-content: space-between
        font-size:$fontsize-large-x
        font-weight: 700
        padding: 20px 0
        color $color-red
        span
          color #000
          font-weight: 700
</style>
