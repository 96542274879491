<template>
  <div>
    <v-header></v-header>
    <!--  登陆页面  -->
    <div class="order-wrap">
      <div class="order-wrap">
        <div class="login-order-wrap">
          <div class="login-order-container">
            <div class="title">登录</div>
            <div class="login-account-container">
              <span>工号：</span>
              <input
                v-model="loginRequestData.account"
                type="text"
                class="order-account"
              />
            </div>
            <div class="login-password-container">
              <span>密码：</span>
              <input
                v-model="loginRequestData.password"
                type="password"
                class="order-password"
              />
            </div>
            <button @click="userLogin">登录</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VHeader from 'components/v-Adminheader/v-header'
import { webUserLogin, getUserAccount } from 'api'
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)

export default {
  name: 'login',
  components: { VHeader },
  data () {
    return {
      // 登录请求参数
      loginRequestData: {
        account: '',
        password: ''
      }
    }
  },
  methods: {
    userLogin () {
      if (!this.loginRequestData.account || !this.loginRequestData.password) {
        this.$createToast({
          txt: '工号或密码输入有误',
          time: 1000
        }).show()
      } else {
        webUserLogin(this.loginRequestData)
          .then((res) => {
            const data = res
            window.localStorage.setItem('access_token', data.access_token)
            getUserAccount(this.loginRequestData).then(res => {
              if (res.code === -1) {
                this.$toast(res.message_description)
              } else {
                if (res.object.roles === 'ADMIN') {
                  Toast.success('登陆成功')
                  var admin = res.object
                  sessionStorage.setItem('loginAdmin', JSON.stringify(admin))
                  this.$router.push({ name: 'admin/order' })
                } else {
                  Toast.fail('用户无权限')
                }
              }
            })
          })
          .catch((err) => {
            console.log(err)
            this.$toast('账号密码有误')
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.list-options-wrap
  display: flex
  justify-content: space-between
  padding: 0 18px

  >>> .cube-radio-group
    width 100%
    border: 0px !important
    font-size: $fontsize-large

  >>> .cube-radio
    padding-left: 0
    padding-right: 15px

  >>> .border-top-1px::before
    border: none

  >>> .cube-radio-group::after
    border: none

.order-wrap
  height 100%

  .list-content
    padding: 0 18px
    height: 80%
    overflow: hidden
    background: $color-white

  .orderList-wrap
    width 100%
    display: flex
    flex-wrap: wrap

    .orderList-container
      width 90%
      margin: 15px auto
      background: $color-white
      border-radius: 5px
      border: 1px dashed  $color-background-sss

      .orderList-top-container
        display: flex
        border-radius: 5px
        font-size: $fontsize-large
        justify-content: space-between
        margin: 0

        .order-state
          display: flex
          font-weight: 700

          span
            font-weight: 700
            color: $color-red

        .order-display-button
          margin-right: 5px
          border-radius: 5px
          border: none
          background: $color-blue
          padding: 5px
          color: #fff

        .order-display-button-disable
          box-shadow: none
          padding: 5px
          margin-right: 5px
          border-radius: 5px
          border: none
          padding: 5px
          color: rgba(255, 0, 0, 0.5)
          background: $color-background-sss

      div
        margin: 8px 0
        font-size: 15px
        display: flex
        align-items: center
        padding-left: 5px

        span
          font-weight: 700

      .orderList-totalPrice
        span
          color: red

  .order-pagination
    margin-top: 10px

.login-order-wrap
  display: flex
  justify-content: center
  position relative
  top: 20%

  .login-order-container
    margin-top 20px
    padding: 0 20px
    flex-direction: column
    border-radius: 5px
    border: 1px solid #ccc
    background: #fff
    display: flex
    justify-content: center

    .title
      text-align: center
      font-size: 20px
      font-weight: 700
      padding: 15px

    .login-account-container
      padding 10px 0
      display: flex
      align-items: center

    .login-password-container
      padding 10px 0
      display: flex
      align-content: center

    input
      border: 2px solid #ccc
      border-radius: 5px

    button
      border: none
      border-radius: 5px
      overflow hidden
      background: #1E90FF
      width 90%
      font-size: 14px
      margin: 10px auto
      padding: 4px 0
      color: #fff
</style>
