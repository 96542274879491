<template>
  <div class="order-wrap">
    <div class="top-container">
      <van-icon @click="goBack" class="top-icon" size="25" name="arrow-left" />
      <div class="top-text">
        扫一扫
      </div>
    </div>
    <cube-scroll
      class="list-content"
      ref="listContent"
    >
      <div class="main">
        <div class="qrcode">
          <qrcode-capture
            :qrcode="qrcode"
            v-show="qrcode"
            :camera="camera"
            :torchActive="torchActive"
            @switchCamera="switchCamera"
            @ClickFlash="ClickFlash"
            @turnCameraOff="turnCameraOff"
            @onDecode="onDecode"
            @onInit="onInit"
          ></qrcode-capture>
        </div>
      </div>
    </cube-scroll>
  </div>
</template>

<script>
import QrcodeCapture from 'components/qrcodeReader/QrcodeReader.vue'

export default {
  data () {
    return {
      qrcode: false,
      torchActive: false,
      camera: 'auto'
    }
  },
  mounted () {
    this.validateLogin()
    this.clickCode()
  },
  components: {
    QrcodeCapture
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 打开相机
    clickCode () {
      // camera:: 'rear'--前摄像头，'front'后摄像头，'off'关闭摄像头，会获取最后一帧显示，'auto'开始获取摄像头
      if (this._isMobile()) {
        console.log('手机端' + this.camera)
        this.qrcode = true
        this.camera = 'rear'
      } else {
        this.qrcode = true
        this.camera = 'auto'
        console.log('PC端' + this.camera)
      }
      console.log('请求摄像头------' + this.camera)
    },
    // 扫码结果回调
    onDecode (result) {
      this.$router.push({
        path: '/admin/takeAMeal',
        query: { encryptionOrderCode: result }
      })
      this.turnCameraOff()
    },
    // 相机反转
    switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
        case 'auto':
          this.camera = 'front'
          break
        default:
          this.$toast('错误')
      }
    },
    // 关闭相机
    turnCameraOff () {
      this.camera = 'off'
      this.qrcode = false
    },
    // 打开手电筒
    ClickFlash () {
      switch (this.torchActive) {
        case true:
          this.torchActive = false
          break
        case false:
          this.torchActive = true
          break
        default:
          this.$toast('错误')
      }
    },

    // 检查是否调用摄像头
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'StreamApiNotSupportedError') {
        } else if (error.name === 'NotAllowedError') {
          this.errorMessage = 'Hey! I need access to your camera'
        } else if (error.name === 'NotFoundError') {
          this.errorMessage = 'Do you even have a camera on your device?'
        } else if (error.name === 'NotSupportedError') {
          this.errorMessage =
            'Seems like this page is served in non-secure context (HTTPS, localhost or file://)'
        } else if (error.name === 'NotReadableError') {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?"
        } else {
          this.errorMessage = 'UNKNOWN ERROR: ' + error.message
        }
      }
    },
    _isMobile () {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    // 登录验证
    validateLogin () {
      var data = sessionStorage.getItem('loginAdmin')
      var loginAdmin = JSON.parse(data)
      if (loginAdmin === null) {
        this.$toast.fail('请进行管理员登录')
        this.$router.push({ name: 'admin/login' })
      } else if (loginAdmin.roles !== 'ADMIN') {
        this.$toast.fail('暂无权限')
        this.$router.push({ name: 'admin/login' })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/variable"
.order-wrap
  background: $color-white
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
.top-container
  width 100%
  background: #87CEEB
  display: flex
  padding: 20px 0
  align-items: center
  .top-icon
    padding: 0 10px
  .top-text
    font-size: 18px
    font-weight: 700
.list-content
  margin: 40px
  width 100%
  height: 80%
  overflow: hidden
  background: $color-white
  .main
    background: $color-background-ssss
    width 80%
    height: 80%
    margin: auto
    padding: 20px
    border-radius: 10px
    .food-name
      display: flex
      justify-content: space-between
      font-size: 20px
      padding-bottom: 20px
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .food-wrap
      display: flex
      flex-direction: column
      .food-container
        display: flex
        flex-direction: column
        margin: 8px 10px
        .food-container-top
          display: flex
          justify-content: space-between
          .food-container-title
            display: flex
            .food-index
              font-size: $fontsize-large
              font-weight: 700
            .food-title
              font-size: $fontsize-large
              font-weight: 700
          .food-num
            font-size: $fontsize-large
            font-weight: 700
            span
              font-size: $fontsize-small-s
              font-weight: 700
          .food-price
            font-size: $fontsize-large
            font-weight: 700
            color: $color-blue

        .food-container-bottom
          background: $color-background-sss
          color: $ccc
          padding: 4px
          border-radius: 5px
          margin: 8px
          font-weight: 700
      .total-price
        display: flex
        justify-content: space-between
        font-size:$fontsize-large-x
        padding-bottom: 10px
        border-bottom: 2px solid #ccc
        font-weight: 700
        span
          color: $color-blue
          font-size:$fontsize-large-xxx
          border-bottom: 1px solid $color-blue
          font-weight: 700
    .order-createTime
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .order-serialNumber
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      border-bottom: 2px solid #ccc
      span
        font-weight: 700
    .oder-state
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      color $color-red
      border-bottom: 2px solid #ccc
      span
        color #000
        font-weight: 700
    .oder-button
      display: flex
      justify-content: space-between
      font-size:$fontsize-large-x
      font-weight: 700
      padding: 20px 0
      span
        color #000
        font-weight: 700
</style>
