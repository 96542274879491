<template>
  <div class="header">
    <div class="top-container">
      <div class="top">
        <span class="icon-zhihuicanyin"></span>
        <span>德信点菜</span>
        <span class="icon-gerenzhongxin" style="width: 120px"> </span>
      </div>
    </div>
    <div class="logo-container">
      <img src="@/assets/Logo.png" alt="" />
    </div>
    <div class="bottom-container">
      <div class="bottom-top">
        <span class="icon-hege"></span>
        德信点菜
      </div>
      <div class="bottom-time">
        <span class="bottom-time-tag">公告</span>
        <p>
         管理员管理
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'v-header',
  computed: {
    ...mapState('eatTime', ['eatTimeInfo'])
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.header
  position relative

  .top-container
    height: 90px
    background: url($imgUrl + "header_bg.jpg") no-repeat
    background-size: 100%

    .top
      display: flex
      align-items: center
      justify-content: space-between
      padding: 20px
      font-size: 18px
      color: #fff

      span:nth-child(2)
        font-size: 20px
        color: $color-blue

  .logo-container
    position: absolute
    width 60px
    height 60px
    top: 32%
    right: 10%
    display: flex
    align-items: center
    justify-content: center
    background: #fff
    border-radius: 10px
    box-shadow: 4px 2px 5px #ccc

    img
      position: absolute
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transform: scale(0.7)

  .bottom-container
    height: 90px
    padding: 0 20px

    .bottom-top
      display: flex
      align-items: center
      font-weight: 600
      padding: 10px 0

      span
        color: #FFD700
        font-size: 22px
        margin-right: 5px

    .bottom-time
      display: flex
      align-items: center
      height: 40px
      background: -webkit-linear-gradient(left, rgb(241, 226, 200), rgb(218, 185, 132))
      background: -o-linear-gradient(right, rgb(241, 226, 200), rgb(218, 185, 132))
      background: -moz-linear-gradient(right, rgb(241, 226, 200), rgb(218, 185, 132))
      background: linear-gradient(to right, rgb(241, 226, 200), rgb(218, 185, 132))
      border-radius: 5px
      overflow: hidden
      box-shadow: 1px 1px 3px #888888
      color: rgb(163, 104, 64)

      .bottom-time-tag
        padding: 4px
        font-size: 12px
        transform: scale(0.9)
        background: -webkit-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: -o-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: -moz-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        border-radius: 5px
        overflow hidden
        color: #fff
        margin: 0 10px
        font-weight: 600

      p
        font-weight: 600
</style>
